@import "styles/_variables.scss";

.title{
    text-align: center;
    margin-bottom: 20px;
}

.subtitle{
    text-align: center;
}
