@import "styles/_variables.scss";

.item {
    display: flex;
    justify-content: space-between;
    margin-top: 64px;

    @media(max-width: $mobilePoint) {
        flex-direction: column-reverse;
        margin-top: 40px;
    }

    &_reversed {
        flex-direction: row-reverse;

        @media(max-width: $mobilePoint) {
            flex-direction: column-reverse;
        }
    }

    &__text {
        &__block {
            max-width: 450px;
            margin: auto 0;

            @media(max-width: $tabletPoint) {
                max-width: 290px;
            }

            @media(max-width: $mobilePoint) {
                max-width: 100%;
            }
        }
    }

    &__title {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 42px;
        color: $mainText;
        margin-bottom: 20px;
    }

    &__types {
        display: flex;
        column-gap: 16px;
        margin-bottom: 20px;
    }

    &__type {
        padding: 9px 12px;
        background-color: #E3EAE6;
        border-radius: 6px;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $greenBackground;
    }

    &__subtitle {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: $mainText;
        margin-bottom: 32px;
    }

    &__image {
        position: relative;
        width: 544px;
        height: 544px;
        @media(max-width: $tabletPoint) {
            width: 352px;
            height: 352px;
        }

        @media(max-width: $mobilePoint) {
            width: 343px;
            height: 343px;
        }
        &__block {
            background-color: #FCFBF8;
            border-radius: 8px;
            width: 544px;
            height: 544px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            @media(max-width: $tabletPoint) {
                width: 352px;
                height: 352px;
                margin: auto 0;
            }

            @media(max-width: $mobilePoint) {
                width: 100%;
                height: 343px;
                margin: 0 auto 20px auto;
            }
        }

        img {
            object-fit: contain;
        }
    }

    &__button {
        max-width: 209px;
    }
}
